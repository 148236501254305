import {
  LogtoAccountApi,
  LogtoCard,
  LogtoFormCard,
  LogtoList,
  LogtoProfileCard,
  LogtoThemeProvider,
  LogtoUserProvider,
  initLocalization
} from "./chunk-CGJUMKLJ.js";

// src/react.ts
import { createComponent } from "@lit/react";
var createReactComponents = (react) => {
  return {
    LogtoFormCard: createComponent({
      tagName: LogtoFormCard.tagName,
      elementClass: LogtoFormCard,
      react
    }),
    LogtoList: createComponent({
      tagName: LogtoList.tagName,
      elementClass: LogtoList,
      react
    }),
    LogtoProfileCard: createComponent({
      tagName: LogtoProfileCard.tagName,
      elementClass: LogtoProfileCard,
      react
    }),
    LogtoCard: createComponent({
      tagName: LogtoCard.tagName,
      elementClass: LogtoCard,
      react
    }),
    LogtoThemeProvider: createComponent({
      tagName: LogtoThemeProvider.tagName,
      elementClass: LogtoThemeProvider,
      react
    }),
    LogtoUserProvider: createComponent({
      tagName: LogtoUserProvider.tagName,
      elementClass: LogtoUserProvider,
      react
    })
  };
};
export {
  LogtoAccountApi,
  createReactComponents,
  initLocalization
};
